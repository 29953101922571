.post__text {
    @apply mt-4 lg:mt-8;

    .__heading {
        @apply font-semibold text-xl lg:text-2xl tracking-tight xl:text-3xl;
    }
}

.post__image-container {
    @apply space-y-2 lg:space-y-4 mt-4 lg:mt-8;

    .post__image {
        @apply relative lg:max-h-120 text-center lg:text-left lg:max-w-120 inline-block;

        .__img-elem {
            @apply h-full w-full rounded-md object-contain object-center lg:object-left my-0;
        }
    }

    .__img-caption {
        @apply text-xs;
    }
}

.post__social__inview-checker {
    @apply lg:hidden;

    &.--hidden {
        @apply h-12;
    }
}

.post__social {
    @apply h-12;

    &.--sticky {
        @apply left-0 bottom-16 z-50 right-0 lg:relative lg:bottom-auto;
    }

    &.--fixed {
        @apply fixed lg:relative;

        a {
            @apply rounded-none lg:rounded;
        }
    }
}

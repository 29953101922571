@import 'form';
@import 'icon';
@import 'type';

h1 {
    @apply font-montserrat;
}

.page__gutter {
    @apply px-4 md:px-16 4xl:px-48;
}

.page__spinner {
    @apply flex items-center justify-center relative py-8;
}

.page__results-grid {
    @apply grid grid-cols-2 gap-1 sm:gap-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-4;
}

.page__results-spacer {
    @apply pt-5 lg:pt-8;
}

.content-page {
    @apply prose mx-auto text-slate-50 lg:prose-xl dark:prose-invert;

    h1 {
        @apply tracking-tighter text-slate-50;
    }

    h2 {
        @apply tracking-tighter text-slate-50;
    }

    .list-header {
        @apply font-semibold text-slate-50 underline;
    }

    .sub-list-header {
        @apply text-slate-50 underline;
    }

    strong {
        @apply text-slate-50;
    }

    a {
        @apply text-bright-turquoise-500 underline;
    }

    code {
        @apply text-slate-50;
    }

    &.--wrap {
        @apply mx-0 w-auto max-w-full;
    }
}

.error-page {
    @apply relative flex  w-full flex-col items-center justify-center space-y-12 rounded-md py-8 text-center;

    .__container {
        @apply flex flex-col items-center justify-center space-y-4 lg:w-1/2 lg:space-y-8 xl:space-y-12;
    }
}

.user-menu {
    .__user-details {
        @apply px-2 py-1.5;

        .nav__item {
            @apply flex flex-col items-start rounded-md px-2 py-1.5 hover:bg-slate-200;

            .__user-fullname {
                @apply truncate text-sm font-bold text-slate-900 w-56;
            }

            .__user-email {
                @apply truncate text-sm tracking-tight text-gray-500 w-56;
            }

            &.nav__item--active {
                @apply bg-stratos-950;

                .__user-fullname,
                .__user-email {
                    @apply text-white;
                }
            }
        }
    }
}

.wrapper {
    @apply relative flex min-h-dvh w-full flex-col items-center bg-stratos-950 pb-16 lg:pb-0;

    .content-container {
        @apply mx-auto h-full w-full flex-1 pt-20 sm:pt-28 lg:pt-36 3xl:pt-40;
        z-index: 25;

        &.--has-location {
            @apply pt-32 lg:pt-36 3xl:pt-40;
        }
    }

    .gradient {
        @apply fixed left-0 top-0 z-10 h-full w-screen bg-gradient-to-b from-stratos-950/70 via-black to-black;
    }
}

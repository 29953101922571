@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    @apply bg-slate-950;
}

a {
    @apply inline-flex items-center text-bright-turquoise-500 transition duration-200 hover:text-cerulean-500 focus:rounded focus:outline-none focus:ring-2 focus:ring-bright-turquoise-500;

    &.--underline {
        @apply underline underline-offset-2;
    }
}

@media screen and (min-width: 640px) {
    @supports selector(::-webkit-scrollbar) {
        .custom__scrollbar {
            @apply pb-1;

            &::-webkit-scrollbar {
                @apply bg-slate-800 rounded-md h-2;
            }

            &::-webkit-scrollbar-track {
                @apply bg-slate-800 rounded-md border border-white p-1;
            }

            &::-webkit-scrollbar-thumb {
                @apply bg-slate-700 rounded-md;
            }
        }
    }
}

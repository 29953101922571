.toast {
    @apply overflow-hidden fixed bottom-4 md:bottom-auto md:top-8 z-50 rounded-md w-11/12 mx-auto drop-shadow-lg translate-y-[100vh] transition-transform duration-300 md:-translate-y-28 md:w-96;

    &.--plain {
        @apply bg-white;

        .__timer-bar {
            @apply bg-cerulean-500;
        }
    }

    &.--success {
        @apply bg-green-100;

        .__timer-bar {
            @apply bg-green-700;
        }

        .__content {
            .__message {
                @apply text-green-700;
            }
        }
    }

    &.--error {
        @apply bg-red-100;

        .__timer-bar {
            @apply bg-red-700;
        }

        .__content {
            .__message {
                @apply text-red-700;
            }
        }
    }

    &.--warning {
        @apply bg-yellow-100;

        .__timer-bar {
            @apply bg-yellow-700;
        }

        .__content {
            .__message {
                @apply text-yellow-700;
            }
        }
    }

    &.--mounted {
        @apply translate-y-0;
    }

    .__content {
        @apply shadow-lg p-4 lg:pl-8 flex items-center space-x-4 lg:space-x-8;

        .__message {
            @apply tracking-tight font-semibold flex-1;
        }
    }

    .__timer-bar {
        @apply absolute bottom-0 h-1 left-0 transform-gpu w-full origin-left;
    }
}

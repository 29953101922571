.search-bar-container {
    @apply relative;

    .search-bar-toggle {
        @apply absolute left-0 top-0 h-full w-full overflow-hidden rounded focus:outline-none focus:ring-2 focus:ring-bright-turquoise-200;
    }

    .search-bar {
        @apply relative flex w-full items-center space-x-1 md:space-x-4 border-b border-slate-200 bg-white;

        .search-input {
            @apply h-12 flex-1 border-0 bg-transparent px-2 md:px-4 text-sm font-medium text-slate-800 transition duration-200 placeholder:text-slate-400 focus:bg-slate-50 focus:outline-none focus:ring-0;

            &.search-input--button {
                @apply placeholder:text-slate-800;
            }
        }

        .search-buttons-container {
            @apply flex h-full items-center justify-center space-x-1 md:space-x-2 pr-2 md:pr-4;

            .search-button {
                @apply flex h-8 w-8 flex-none items-center justify-center rounded-full bg-slate-50 transition-colors duration-200 hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-white;
            }
        }

        &.search-bar__button {
            @apply pointer-events-none rounded border-none bg-bright-turquoise-500 shadow-md;

            .search-buttons-container {
                .search-button {
                    @apply bg-bright-turquoise-300;
                }
            }
        }
    }
}

.search-modal {
    @apply fixed bottom-0 left-0 top-0 z-50 flex h-screen w-full flex-col items-center overflow-y-auto bg-black/40 p-2 md:px-4 opacity-0 backdrop-blur-sm transition duration-200 md:py-16 xl:py-24;

    .search-modal__container {
        @apply relative w-full translate-y-8 transform-gpu overflow-hidden rounded bg-white transition duration-200 sm:w-104 xl:w-112;

        .search-modal__input-container {
            .search-modal__filters {
                @apply space-y-2 border-b border-slate-200 px-2 md:px-4 py-2;

                .search-filters__label {
                    @apply text-xs font-medium tracking-tight text-slate-400;
                }

                .search-modal__datasource-selector {
                    @apply flex space-x-1 md:space-x-2;

                    button {
                        @apply flex flex-1 justify-start px-2 md:px-3;

                        .search-filter__radio {
                            @apply block h-4 w-4 flex-none rounded-full bg-slate-400 transition duration-300;
                        }

                        .search-filter__radio-label {
                            @apply flex-1 text-center text-xs capitalize md:text-sm;
                        }

                        &:disabled {
                            @apply border border-bright-turquoise-500 bg-slate-100;

                            .search-filter__radio {
                                @apply bg-bright-turquoise-500;
                            }
                        }
                    }
                }
            }
        }

        .search-modal__results-container {
            @apply max-h-104 divide-y divide-slate-400 overflow-y-auto bg-white md:max-h-80;
        }

        .search-modal__busy-container {
            @apply absolute left-0 top-0 h-full w-full bg-white/90;
        }

        .search-modal_no-results-container {
            @apply flex flex-col items-center justify-center space-y-4 bg-slate-100 p-8 text-center;

            .search-modal_no-results-title {
                @apply font-semibold;
            }

            .search-modal_no-results-description {
                @apply text-sm;
            }
        }
    }

    .search-result {
        @apply relative flex min-h-20 w-full items-center space-x-4 bg-white p-4 transition duration-200 hover:bg-slate-100 focus:bg-slate-200 focus:outline-none;

        .search-result__image {
            @apply flex h-20 w-20 flex-none items-center justify-center overflow-hidden rounded bg-white;

            .--placeholder {
                @apply opacity-5 w-8 h-8;
                filter: grayscale(1);
            }
        }

        .search-result__label-container {
            @apply flex flex-col w-full items-start space-y-1.5 xl:space-y-0.5;

            .search-result__text {
                @apply flex flex-col items-start;

                .search-result__label {
                    @apply truncate text-sm font-bold leading-4 tracking-tight text-slate-500 xl:text-base xl:leading-5;
                }

                .search-result__sub-label {
                    @apply line-clamp-2 text-left text-sm font-medium leading-4 tracking-tight text-slate-400 xl:text-sm;
                }

                .search-result__summary {
                    @apply truncate text-left text-xs leading-4 tracking-tight text-slate-400 xl:text-sm;
                }
            }

            .search-result__tags {
                @apply flex flex-wrap;

                .search-result__tag {
                    @apply mr-1 mb-1;
                }
            }
        }
    }

    &.search-modal--ready {
        @apply opacity-100;

        .search-modal__container {
            @apply translate-y-0;
        }
    }
}

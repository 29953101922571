.app-aside {
    @apply fixed bottom-0 h-16 bg-black w-full z-30 lg:hidden;

    .__nav {
        @apply flex items-center justify-center h-full;

        .__item {
            @apply flex-1 w-full flex justify-center;
        }
    }

    .aside-button {
        @apply flex flex-col space-y-0.5 items-center justify-center;

        .__icon {
            @apply text-white flex flex-col items-center;
        }

        .__label {
            @apply text-white text-xs font-medium tracking-tight;
        }
    }
}

.smart-image {
    @apply inline-flex items-center justify-center relative w-full h-auto;

    img {
        @apply opacity-0 transition-opacity duration-300;
    }

    .skeleton-loader {
        @apply w-full h-full absolute top-0 left-0;
    }

    &.--fit-content {
        @apply w-full h-full;
    }

    &.--loaded {
        img {
            @apply opacity-100;
        }

        .skeleton-loader {
            @apply hidden;
        }
    }

    &.--circle {
        @apply rounded-full overflow-hidden;
    }
}

.icon {
	@apply inline-flex text-inherit;

	.material-symbols-rounded {
		font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' -25, 'opsz' 48;
	}

	&.icon--sm {
		@apply text-2xl;
	}

	&.icon--md {
		@apply text-4xl;
	}

	&.icon--lg {
		@apply text-5xl;
	}

	&.icon--xl {
		@apply text-6xl;
	}
}

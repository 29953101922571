a.button,
button.button {
    @apply relative inline-flex w-auto flex-none select-none items-center rounded no-underline transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-white shadow-sm;

    .button__label {
        @apply flex-1 font-semibold tracking-tight transition duration-200 truncate;

        &.button__label--left {
            @apply text-left;
        }

        &.button__label--center {
            @apply text-center;
        }

        &.button__label--right {
            @apply text-right;
        }
    }

    .icon__container {
        @apply flex items-center justify-center;
    }

    .icon__placeholder {
        @apply block;
    }

    &.button--icon-size-sm {
        .icon__container,
        .icon__placeholder {
            @apply h-6 w-6;
        }
    }

    &.button--icon-size-base {
        .icon__container,
        .icon__placeholder {
            @apply h-7 w-7;
        }
    }

    &.button--icon-size-lg {
        .icon__container,
        .icon__placeholder {
            @apply h-9 w-9;
        }
    }

    &.button--icon-size-xl {
        .icon__container,
        .icon__placeholder {
            @apply h-11 w-11;
        }
    }

    &.button--icon-size-xxl {
        .icon__container,
        .icon__placeholder {
            @apply h-20 w-20;
        }
    }

    &.button--small {
        @apply h-10;

        .button__label {
            @apply text-sm;
        }

        &.button--has-padding-horizontal {
            @apply px-3 lg:px-3.5 4xl:px-4;
        }
    }

    &.button--extra-small {
        @apply h-6;

        .button__label {
            @apply text-xs font-medium;
        }

        &.button--has-padding-horizontal {
            @apply px-1.5;
        }
    }

    &.button--default {
        @apply h-12;

        .button__label {
            @apply text-base;
        }
    }

    &.button--large {
        @apply h-14;

        .button__label {
            @apply text-lg;
        }
    }

    &.button--primary {
        @apply bg-bright-turquoise-500 hover:bg-cerulean-500;

        .button__label,
        .icon__container {
            @apply text-cerulean-950;
        }
    }

    &.button--secondary {
        @apply bg-cerulean-500 hover:bg-cerulean-700;

        .button__label,
        .icon__container {
            @apply text-cerulean-950;
        }
    }

    &.button--outline {
        @apply border border-bright-turquoise-500 hover:border-bright-turquoise-600 shadow-none;

        .button__label,
        .icon__container {
            @apply text-bright-turquoise-500;
        }

        &:hover {
            .button__label,
            .icon__container {
                @apply text-bright-turquoise-600;
            }
        }
    }

    &.button--warning {
        @apply border border-red-500 hover:border-red-600;

        .button__label,
        .icon__container {
            @apply text-red-500;
        }

        &:hover {
            .button__label,
            .icon__container {
                @apply text-red-600;
            }
        }
    }

    &.button--white {
        @apply border border-slate-200 bg-white hover:border-slate-500 focus:ring-slate-900;

        .button__label,
        .icon__container {
            @apply text-slate-700;
        }

        &.button--disabled {
            @apply bg-gray-200;
        }
    }

    &.button--black {
        @apply bg-cerulean-950 hover:bg-cerulean-800 focus:ring-stratos-950;

        .button__label,
        .icon__container {
            @apply text-white;
        }

        &.button--disabled {
            @apply bg-gray-800;
        }
    }

    &.button--apple {
        @apply bg-black;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--apple-white {
        @apply bg-white;

        .button__label,
        .icon__container {
            @apply text-black;
        }
    }

    &.button--facebook {
        @apply bg-facebook;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--instagram {
        @apply bg-instagram;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--google {
        @apply border border-slate-100 bg-white;

        .button__label,
        .icon__container {
            @apply text-black;
        }
    }

    &.button--spotify {
        @apply bg-spotify;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--deezer {
        @apply bg-deezer;

        .button__label,
        .icon__container {
            @apply text-black;
        }
    }

    &.button--youtube {
        @apply bg-youtube;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--twitter,
    &.button--x {
        @apply bg-x;

        .button__label,
        .icon__container {
            @apply text-white;
        }
    }

    &.button--outline-primary {
        @apply border border-bright-turquoise-500 hover:border-bright-turquoise-600 shadow-none focus:ring-2 focus:ring-bright-turquoise-500;

        .icon__container,
        .button__label {
            @apply text-bright-turquoise-500 hover:text-cerulean-500;
        }
    }

    &.button--outline-secondary {
        @apply border border-cerulean-500 hover:border-cerulean-700 shadow-none focus:ring-2 focus:ring-cerulean-500;

        .icon__container,
        .button__label {
            @apply text-cerulean-700 hover:text-cerulean-900;
        }
    }

    &.button--outline-black {
        @apply border border-slate-500 hover:border-black focus:ring-slate-500 shadow-none;

        .icon__container,
        .button__label {
            @apply text-slate-500 hover:text-black;
        }
    }

    &.button--full-width {
        @apply w-full justify-center;
    }

    &.button--disabled {
        @apply pointer-events-none border-opacity-10 bg-opacity-30 shadow-none;

        .button__label,
        .icon__container {
            @apply text-opacity-30;
        }
    }

    &.button--has-left-icon,
    &.button--has-right-icon {
        @apply px-1;

        .button__label {
            @apply flex-1;
        }
    }

    &.button--has-left-icon {
        @apply pr-6;
    }

    &.button--has-right-icon {
        @apply pl-6;
    }

    &.button--has-no-children {
        @apply pr-1;
    }

    &.button--has-padding-horizontal {
        @apply px-4 lg:px-6;

        &.button--has-left-icon,
        &.button--has-right-icon {
            .button__label {
                @apply px-2 md:px-4 lg:px-6;
            }
        }
    }

    &.button--has-no-padding {
        @apply px-2 space-x-1;
    }

    &.button--transparent {
        @apply border-0 bg-transparent focus:ring-black shadow-none;

        &:hover {
            .button__label {
                @apply text-bright-turquoise-700;
            }
        }

        &.button--disabled {
            .button__label {
                @apply text-slate-400;
            }
        }
    }

    &.button--busy {
        .button__label,
        .icon__container {
            @apply opacity-0;
        }
    }

    &.button--nobg-noborder {
        @apply border-0 bg-transparent shadow-none;
    }

    &.button--link {
        @apply h-auto p-0 border-0 bg-transparent hover:bg-transparent shadow-none;
    }

    &.button--underline {
        @apply underline underline-offset-2;
    }

    &.button--circle {
        @apply rounded-full;
    }

    &.button--dark-mode {
        @apply focus:ring-bright-turquoise-500;
    }
}

.spinner {
    @apply mx-0 inline-flex items-center;

    svg {
        @apply animate-spin;
    }

    &.spinner--primary,
    &.spinner--facebook {
        @apply text-white;
    }

    &.spinner--secondary,
    &.spinner--apple-white {
        @apply text-black;
    }

    &.spinner--akashic {
        @apply text-bright-turquoise-500;
    }

    &.spinner--outline {
        @apply text-bright-turquoise-500;
    }

    &.spinner--tiny {
        svg {
            @apply h-3 w-3;
        }
    }

    &.spinner--small {
        svg {
            @apply h-5 w-5;
        }
    }

    &.spinner--medium {
        svg {
            @apply h-10 w-10;
        }
    }

    &.spinner--large {
        svg {
            @apply h-14 w-14;
        }
    }

    &.spinner--fit-container {
        @apply absolute left-0 top-0 h-full w-full;
    }

    &.spinner--align-center {
        @apply justify-center;
    }

    &.spinner--align-left {
        @apply justify-start;
    }

    &.spinner--align-right {
        @apply justify-end;
    }
}

.card {
    @apply overflow-hidden relative;

    .__container {
        @apply flex items-center space-x-2 rounded-md p-2 md:space-x-4 lg:p-4 overflow-hidden;

        .__icon-container,
        .__button-hint {
            @apply flex flex-col justify-center text-white;
        }

        .__text-container {
            @apply flex flex-col h-full space-y-1 overflow-hidden w-full;

            .__title-component {
                @apply mb-2;
            }

            .__title {
                @apply font-bold;
            }

            .__text {
                @apply text-sm;
            }

            .__link {
                @apply tracking-tight text-bright-turquoise-500 underline underline-offset-2 ring-0 focus:ring-0;
            }

            &.--inline {
                @apply flex-row space-x-2;
            }
        }
    }

    &.card--primary {
        .__container {
            @apply bg-bright-turquoise-500;

            .__icon-container,
            .__button-hint {
                @apply text-cerulean-950;
            }

            .__text-container {
                .__title {
                    @apply text-cerulean-950;
                }

                .__text {
                    @apply text-cerulean-900;
                }
            }
        }
    }

    &.card--secondary {
        .__container {
            @apply bg-cerulean-500;

            .__icon-container,
            .__button-hint {
                @apply text-cerulean-950;
            }

            .__text-container {
                .__title {
                    @apply text-cerulean-950;
                }

                .__text {
                    @apply text-cerulean-900;
                }
            }
        }
    }

    &.card--black {
        .__container {
            @apply bg-slate-900;

            .__icon-container,
            .__button-hint {
                @apply text-slate-300;
            }

            .__text-container {
                .__title {
                    @apply text-slate-200;
                }

                .__text {
                    @apply text-slate-300;
                }
            }
        }
    }

    &.card--outline {
        .__container {
            @apply border border-bright-turquoise-500;

            .__icon-container,
            .__button-hint {
                @apply text-bright-turquoise-500;
            }

            .__text-container {
                .__title {
                    @apply text-bright-turquoise-500;
                }

                .__text {
                    @apply text-bright-turquoise-600;
                }
            }
        }
    }

    &.card--outline-black {
        .__container {
            @apply border border-slate-500;

            .__icon-container,
            .__button-hint {
                @apply text-slate-300;
            }

            .__text-container {
                .__title {
                    @apply text-slate-300;
                }

                .__text {
                    @apply text-slate-400;
                }
            }
        }
    }

    &.card--transparent {
        .__container {
            .__icon-container,
            .__button-hint {
                @apply text-bright-turquoise-500;
            }

            .__text-container {
                .__title {
                    @apply text-bright-turquoise-500;
                }

                .__text {
                    @apply text-bright-turquoise-600;
                }
            }
        }
    }

    &.card--size-default {
        .__container {
            @apply flex items-center space-x-3 p-3 md:space-x-4 lg:p-4;
        }
    }

    &.card--size-small {
        .__container {
            @apply flex items-center space-x-2 p-2 md:space-x-2 lg:px-2.5 lg:py-2;

            .__text-container {
                @apply space-y-0;

                .__title {
                    @apply text-xs font-semibold;
                }

                .__text {
                    @apply text-xs leading-tight;
                }
            }
        }
    }

    &.card--size-large {
        .__container {
            @apply flex items-center space-x-4 p-4 md:space-x-8 lg:p-8;

            .__text-container {
                @apply space-y-0;

                .__title {
                    @apply text-xl font-semibold;
                }

                .__text {
                    @apply text-lg;
                }
            }
        }
    }

    &.card--wrap {
        @apply w-auto inline-block;
    }

    &.card--truncate {
        .__container {
            .__text-container {
                .__title {
                    @apply truncate;
                }

                .__text {
                    @apply truncate;
                }
            }
        }
    }

    &.card--busy {
        .__container {
            @apply opacity-5 select-none;
        }
    }

    &.card--fit-content {
        @apply h-full w-full;

        .__container {
            @apply h-full w-full items-start;
        }
    }

    &.card--remove-padding {
        .__container {
            @apply p-0 md:p-0;
        }
    }
}

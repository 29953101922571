.dropdown {
	@apply relative inline-block;

	.__menu {
		@apply absolute right-0 z-10 mt-2.5 rounded bg-white shadow-2xl;

		.__inner-container {
			@apply overflow-hidden rounded shadow-md;

			.__menu-section {
				@apply border-t border-slate-100 py-1;

				.__menu-section__label {
					@apply flex items-center space-x-2 px-4 py-1 text-xs font-bold uppercase tracking-tight;

					.nav__item-icon {
						@apply flex text-slate-400;
					}
				}

				.nav__item {
					@apply flex items-center space-x-2 bg-white px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-100 focus:rounded-none focus:ring-0;

					.nav__item-icon {
						@apply flex text-slate-400;
					}

					&.nav__item--active {
						@apply bg-stratos-950 text-white;

						.nav__item-icon {
							@apply text-white;
						}
					}
				}
			}
		}

		&.--wrapped {
			@apply w-auto;
		}
	}
}

.a11y__button {
    @apply rounded-md p-0.5 ring-2 ring-inset ring-slate-950 ring-transparent transition duration-200 focus:outline-none focus:ring-bright-turquoise-500;

    &.--enabled {
        @apply cursor-pointer hover:ring-white;
    }

    &.--active {
        @apply ring-bright-turquoise-500;
    }

    &.--height-full {
        @apply h-full;
    }

    &.--height-auto {
        @apply h-auto;
    }
}

.bone {
    @apply relative overflow-hidden flex-none;

    .__animation {
        @apply absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-transparent;
        animation: animate infinite 1.5s ease-in-out;
    }

    .__container {
        @apply relative overflow-hidden w-full h-full;

        &.--rounded {
            @apply rounded-md;
        }

        &.--dark {
            @apply bg-gray-50;

            .__animation {
                @apply via-gray-100;
            }
        }

        &.--light {
            @apply bg-white/10;

            .__animation {
                @apply via-white/5;
            }
        }

        &.--transparent {
            @apply bg-transparent;
        }

        &.--circle {
            @apply rounded-full;
        }
    }

    @keyframes animate {
        from {
            @apply -left-full;
        }

        to {
            @apply left-full;
        }
    }
}

.icon__container {
    @apply inline-flex items-center justify-center;
}

.icon-button {
    @apply relative inline-flex items-center justify-center transition duration-200 focus:outline-none focus:ring-2 focus:ring-white ease-in-out flex-none;

    &.icon-button--sm {
        @apply w-6 h-6;
    }

    &.icon-button--base {
        @apply w-7 h-7;
    }

    &.icon-button--lg {
        @apply w-9 h-9;
    }

    &.icon-button--xl {
        @apply w-11 h-11;
    }

    &.icon-button--xxl {
        @apply w-20 h-20;
    }

    &.icon-button--theme-primary {
        @apply bg-bright-turquoise-500 text-cerulean-900 hover:bg-cerulean-500;
    }

    &.icon-button--theme-secondary {
        @apply bg-cerulean-500 text-white hover:bg-cerulean-700;
    }

    &.icon-button--theme-outline {
        @apply border border-chestnut-rose-500  text-chestnut-rose-500 hover:border-bright-turquoise-500 hover:text-bright-turquoise-500;
    }

    &.icon-button--theme-white {
        @apply text-white hover:text-cerulean-900;
    }

    &.icon-button--theme-red {
        @apply bg-red-500 text-white hover:bg-red-500;
    }

    &.icon-button--theme-green {
        @apply text-green-500 hover:text-green-900;
    }

    &.icon-button--theme-transparent {
        @apply rounded-sm text-white/20 hover:text-bright-turquoise-500 focus:text-bright-turquoise-500 focus:ring-bright-turquoise-500;
    }

    &.icon-button--theme-spotify {
        @apply rounded-sm text-spotify hover:text-white focus:text-white focus:ring-bright-turquoise-500;
    }

    &.icon-button--theme-deezer {
        @apply rounded-sm text-deezer hover:text-white focus:text-white focus:ring-bright-turquoise-500;
    }

    &.icon-button--theme-outline-red {
        @apply rounded-sm text-red-500 hover:text-red-900 focus:ring-red-500;
    }

    &.icon-button--theme-outline-primary {
        @apply border border-bright-turquoise-500 rounded-sm text-bright-turquoise-500 hover:text-bright-turquoise-900 focus:ring-bright-turquoise-500;
    }

    &.icon-button--theme-outline-secondary {
        @apply border border-cerulean-500 rounded-sm text-cerulean-500 hover:text-cerulean-900 focus:ring-cerulean-500;
    }

    &.icon-button--theme-outline-black {
        @apply rounded-sm text-slate-500 hover:text-slate-900 focus:ring-slate-500;
    }

    &.icon-button--rounded {
        @apply rounded-full;
    }

    &.icon--nudge {
        @apply transform-gpu origin-center scale-125;
    }

    &.icon--disable-hover {
        &.icon-button--theme-outline-primary {
            @apply hover:text-bright-turquoise-500;
        }
    }

    &.icon--transparent {
        @apply border-none;
    }
}

header.app-header {
    @apply fixed z-30 h-16 w-full border-b border-cerulean-900 bg-stratos-950 shadow-xl transition duration-300 lg:h-20;

    .__nav-container {
        @apply flex h-16 lg:h-20 w-full items-center shadow-xl;

        .nav-wrapper {
            @apply relative flex w-full items-center xl:space-x-8 3xl:space-x-16;

            .menu-brand-container {
                @apply flex flex-1 items-center;

                .__mobile-menu {
                    @apply inline-flex xl:hidden mr-4;
                }

                .brand {
                    @apply flex h-10 md:h-11 items-center mx-0;

                    .logo {
                        @apply relative w-12 h-9 overflow-hidden lg:w-32 lg:h-11;

                        .__mobile-img,
                        .__desktop-img {
                            @apply h-auto w-auto object-contain;
                        }

                        .__mobile-img {
                            @apply inline-block lg:hidden w-12 h-9;

                            .__img {
                                @apply block w-12 h-9;
                            }
                        }

                        .__desktop-img {
                            @apply hidden lg:inline-block w-32 h-11;

                            .__img {
                                @apply block w-32 h-11;
                            }
                        }
                    }
                }
            }

            .search-container {
                @apply hidden flex-1 3xl:block;
            }

            .mobile-menu-items {
                @apply flex flex-1 items-center justify-end space-x-4 xl:hidden;

                .mobile__search-container {
                    @apply hidden lg:block;
                }

                .auth__buttons {
                    @apply flex;

                    &.--xl {
                        @apply hidden sm:space-x-2 xl:block;
                    }

                    &.--xs {
                        @apply sm:space-x-4 xl:hidden;

                        .auth__button {
                            @apply first:hidden sm:first:block;
                        }
                    }
                }
            }

            .nav__search--button {
                @apply hidden xl:block 3xl:hidden;
            }

            .mobile-button {
                @apply inline-flex h-10 w-10 items-center justify-center rounded text-bright-turquoise-500 focus:outline-none focus:ring-2 focus:ring-bright-turquoise-500;

                &.user-button {
                    @apply rounded-full bg-bright-turquoise-500 text-white focus:ring-white;
                }
            }

            .__location-card {
                @apply w-9 lg:w-44 xl:w-52 inline-flex justify-end;
            }

            &.--authenticated {
                @apply space-x-4 lg:space-x-8 xl:space-x-16;

                .brand {
                    @apply pl-4 lg:pl-0 flex-1 justify-start lg:flex-none;
                }

                .mobile-menu-items {
                    @apply flex-none;
                }
            }

            &.--not-authenticated {
                @apply space-x-4 lg:space-x-8 xl:space-x-16;

                .brand {
                    @apply flex-1 lg:flex-none justify-start;
                }

                .mobile-menu-items {
                    @apply flex-none;
                }
            }

            .main-nav {
                @apply hidden justify-end space-x-4 xl:flex xl:flex-1 xl:items-center 2xl:space-x-6 2xl:flex-none;

                .nav__separator {
                    @apply h-6 w-px bg-white;
                }

                .nav__item {
                    @apply font-medium text-bright-turquoise-500 focus:rounded focus:outline-none focus:ring-2 focus:ring-bright-turquoise-500 md:text-sm xl:text-base hover:text-cerulean-800 transition duration-200 whitespace-nowrap;

                    &.nav__item--active {
                        @apply text-white;
                    }
                }

                .nav__item--group {
                    @apply relative space-x-4 pl-4 inline-flex items-center;
                }

                @media screen and (min-width: 1600px) {
                    li:nth-child(2) {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }

    .__location-details {
        @apply bg-black xl:hidden flex items-center h-12;
    }

    &.--has-location {
        @apply h-28 lg:h-20;
    }
}
